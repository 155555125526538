import { memo } from "react";
// @mui
import { Box } from "@mui/material";
//
import NotFoundAnimation from "../../assets/animations/data_not_found";
import Lottie from "lottie-react";
// ----------------------------------------------------------------------

function DataNotFound({ sx, ...other }) {
  return (
    <Box {...sx}{...other}>
      <Lottie
        style={{ margin: "auto" }}
        animationData={NotFoundAnimation}
        loop={true}
      />
    </Box>
  );
}

export default memo(DataNotFound);
