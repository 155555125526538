// ----------------------------------------------------------------------

export default function TextField(theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: "#ffffff",
          color: "#676767",
          "& .MuiInputBase-root": {
            minHeight: 48,
          },
        },
      },
    },
  };
}
