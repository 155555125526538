import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, Link } from "@mui/material";

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.logo.primary;

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 300,
        height: 100,
        display: "inline-flex",
        ...sx,
      }}
      {...other}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 5058.95 1045.93"
      >
        <defs>
        <linearGradient id="BG0" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="100%" stopColor="#700DC0" />
          </linearGradient>

          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="100%" stopColor={PRIMARY_MAIN}  />
          </linearGradient>

          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="100%" stopColor="#B436F9" />
          </linearGradient>

          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="100%" stopColor="#9213C8" />
          </linearGradient>

          <linearGradient id="BG4" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="100%" stopColor={PRIMARY_MAIN}/>
          </linearGradient>
        </defs>

        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
          <polygon
            fill="url(#BG0)"
            points="321.28,0 270.46,213.08 430.94,213.08 388.37,392.5 604.57,536.49 682.36,213.08 838.38,213.08 888.97,0 "
          />
          <path
            fill="url(#BG1)"
            d="M1064.16 572.59l0 -2.45c0.02,-1.45 -0.02,-3 -0.02,-4.47 0,-179.69 145.72,-325.41 325.41,-325.41 0.68,0 1.35,0 2.03,0 4.63,-0.19 9.32,-0.3 13.97,-0.3 91.51,0 178.56,39.83 238.35,109.08l-89.15 96.73c-44.58,-46.58 -90.94,-78.9 -163.82,-78.9 -105.2,0 -181.65,90.94 -181.65,200.6l0 2.45c0,111.44 77.79,203.27 188.78,203.27 68.2,0 118.35,-32.32 163.82,-78.9l87.15 86.03c-60.52,75.28 -151.99,119.17 -248.64,119.17 -3.27,0 -6.7,-0.08 -9.93,-0.16 -1.28,-0.01 -2.69,0.03 -4.03,0.03 -177.97,0 -322.29,-144.33 -322.29,-322.29 0,-1.46 0.04,-3.07 0.04,-4.49l-0.01 0.01z"
          />
          <path
            fill="url(#BG1)"
            d="M1699.83 220.88l111.44 0 0 263.67c31.81,-53.6 89.52,-86.49 151.85,-86.49 0.5,0 1.01,0 1.51,0 111.44,0 174.52,74.44 174.52,188.56l-0.01 312.72 -111.44 0 0 -279.28c0,-76 -38.11,-119.69 -104.98,-119.69 -66.86,0 -111.44,44.58 -111.44,121.7l-0 277.27 -111.44 0 0 -678.46z"
          />
          <path
            fill="url(#BG1)"
            d="M2191.07 644.36l0 -2.01c0,-140.2 99.41,-255.43 239.6,-255.43 156.02,0 235.14,122.81 235.14,263.9 0,10.25 0,20.5 -1.78,31.65l-360.63 -0c6.52,70.48 65.7,124.45 136.5,124.45 1.59,0 3.25,-0.03 4.82,-0.08 0.56,0.01 1.19,0.01 1.75,0.01 50.24,0 98.17,-21.22 131.96,-58.41l66.87 58.39c-47.09,58.58 -118.26,92.65 -193.44,92.65 -2.88,0 -5.85,-0.06 -8.7,-0.15 -2.32,0.07 -4.75,0.1 -7.11,0.1 -135.38,0 -245.17,-109.79 -245.17,-245.17 0,-3.29 0.09,-6.65 0.21,-9.91l-0.02 0zm363.53 -36.33c-7.58,-72.44 -50.15,-129.05 -124.59,-129.05 -68.65,0 -117.01,53.05 -127.27,129.05l251.86 0z"
          />
          <path
            fill="url(#BG1)"
            d="M2716.86 645.26l0 -1.78c0,-1.2 -0.03,-2.57 -0.03,-3.8 0,-139.69 113.28,-252.97 252.97,-252.97 0.52,0 1.05,0 1.58,0 3.43,-0.13 6.94,-0.22 10.38,-0.22 71.24,0 139,31.01 185.55,84.92l-69.54 75.11c-31.13,-38.42 -77.83,-60.93 -127.27,-61.3 -81.8,0 -141.31,70.65 -141.31,156.02l0 2.01c0,87.15 60.4,157.8 146.88,157.8 49.2,-1.35 95.55,-23.68 127.27,-61.31l66.87 66.87c-48.84,58.22 -121.04,91.91 -197.06,91.91 -140.58,0 -255.17,-112.95 -257.18,-253.51l0.89 0.24z"
          />
          <path
            fill="url(#BG1)"
            d="M3223.25 220.88l111.44 0 0 263.67c22.29,-45.91 163.6,-97.63 163.6,-97.63l51.71 76.45c0,0 -147.55,66.87 -147.55,89.15 67.53,100.4 145.11,194.64 230.91,279.99l-66.87 66.87c0,0 -98.07,-87.64 -231.81,-281.1l0 281.06 -111.44 0 0 -678.46z"
          />
          <path
            fill="url(#BG1)"
            d="M3746.59 754.91l0 -258.32 -62.19 0 0 -96.51 62.19 0 0 -133.73 112.56 0 0 133.73 131.72 0 0 96.51 -131.72 0 0 240.72c0,44.58 22.29,61.29 60.4,61.29 24.16,-0.03 48.01,-5.77 69.54,-16.72l0 91.83c-29.57,16.78 -63.1,25.67 -97.14,25.67 -1.34,0 -2.74,-0.02 -4.06,-0.05 -82.69,0.02 -141.31,-36.76 -141.31,-144.42z"
          />
          <path
            fill="url(#BG1)"
            d="M4616.29 408.99l111.44 0 0 76.23c31.84,-53.6 89.6,-86.48 151.96,-86.48 0.37,0 0.8,0 1.17,0 111.44,0 174.74,74.44 174.74,188.56l-0.01 312.05 -111.44 0 0 -279.28c0,-76 -38.11,-119.69 -104.98,-119.69 -66.86,0 -111.44,44.58 -111.44,121.7l-0 277.27 -111.44 0 0 -490.35z"
          />
          <path
            fill="url(#BG2)"
            d="M468.39 786.34c-4.51,8.91 -9.83,17.43 -15.82,25.4 -29.53,37.96 -75,60.16 -123.09,60.16 -86.15,0 -156.02,-69.87 -156.02,-156.02 0,-86.15 69.87,-156.02 156.02,-156.02 34.65,0 68.32,11.54 95.68,32.78 13.6,10.98 25.24,24.29 34.33,39.23l144.65 -94.28c-60.65,-93.02 -164.28,-149.17 -275.38,-149.17 -181.54,0 -328.76,147.21 -328.76,328.75 0,181.54 147.22,328.76 328.76,328.76 101.46,0 197.31,-46.92 259.56,-126.99 12.95,-16.54 24.27,-34.44 33.66,-53.27l-153.59 -79.33z"
          />
          <path
            fill="url(#BG3)"
            d="M531.24 456.25c-41.73,-32.48 -90.78,-54.41 -142.85,-63.73l-6.24 25.86 -34.13 142.85c45.9,5.53 86.88,31.52 111.46,70.65l144.65 -94.28c-19.57,-30.95 -44.26,-58.52 -72.87,-81.33l-0.02 -0.01z"
          />
          <path
            fill="url(#BG4)"
            d="M4299.58 379.45c144.13,0 260.97,115.75 260.97,258.53 0,142.79 -116.84,258.53 -260.97,258.53 -144.13,0 -260.97,-115.75 -260.97,-258.53 0,-142.79 116.84,-258.53 260.97,-258.53zm-1.22 101.42c84.19,0 152.44,70.88 152.44,158.32 0,87.44 -68.25,158.32 -152.44,158.32 -84.19,0 -152.44,-70.88 -152.44,-158.32 0,-87.44 68.25,-158.32 152.44,-158.32z"
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
