// @mui
import { Typography, Stack } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../../store/auth";
import { PATH_AUTH } from "../../../routes/paths";
import { useQueryClient } from "@tanstack/react-query";

export default function Logout() {
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);
  const queryClient = useQueryClient();

  const handleExit = () => {
    logout();
    queryClient.clear()
    navigate(PATH_AUTH.login, { replace: true });
  };

  return (
    <Stack
      onClick={handleExit}
      direction="row"
      alignContent="center"
      alignItems="center"
      spacing={4}
      sx={{
        px: 4,
        py: 1.5,
        cursor: "pointer",
        "&:hover": {
          color: (theme) => theme.palette.text.primary,
          backgroundColor: (theme) => theme.palette.action.hover,
        },
      }}
    >
      <ExitToApp />
      <Typography variant="subtitle" noWrap>
        Salir
      </Typography>
    </Stack>
  );
}
