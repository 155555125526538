import { memo } from "react";
// @mui
import { Box } from "@mui/material";
//
import ActivesAnimation from "../../assets/animations/animation_assets";
import Lottie from "lottie-react";
// ----------------------------------------------------------------------

function Activesllustration({ sx, loop, ...other }) {
  return (
    <Box {...sx} {...other} width={160}>
      <Lottie
        style={{ margin: "auto" }}
        animationData={ActivesAnimation}
        loop={loop ?? true}
        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
      />
    </Box>
  );
}

export default memo(Activesllustration);
