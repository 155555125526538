import PropTypes from "prop-types";
// @mui
import { useTheme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// config
import { NAV } from "../../../config-global";
// components
import { useSettingsContext } from "../../../components/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

// ----------------------------------------------------------------------

NavToggleButton.propTypes = {
  sx: PropTypes.object,
};

export default function NavToggleButton({ sx, ...other }) {
  const theme = useTheme();

  const { themeLayout, onToggleLayout } = useSettingsContext();

  // const isDesktop = useResponsive("up", "lg");
  const isDesktop = useResponsive("up", "md");

  if (!isDesktop) {
    return null;
  }

  return (
    <IconButton
      size="small"
      onClick={onToggleLayout}
      sx={{
        p: 0.5,
        top: 32,
        position: "fixed",
        left: NAV.W_DASHBOARD - 12,
        zIndex: theme.zIndex.appBar + 1,
        border: `solid 1px ${theme.palette.divider}`,
        width: 25,
        height: 25,
        ...bgBlur({ opacity: 0.48, color: theme.palette.background.default }),
        "&:hover": {
          bgcolor: "background.primary",
        },
        ...sx,
      }}
      {...other}
    >
      <FontAwesomeIcon
        size="xs"
        icon={themeLayout === "vertical" ? faChevronLeft : faChevronRight}
        color="text.primary"
      />
    </IconButton>
  );
}
