import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export const useAuthStore = create(
  persist(
    (set, get) => ({
      token: null,
      isAuth: false,
      tokenExp: null,
      profile: null,
      preToken: false,
      user: null,
      validationEmail: null,
      registervalidation: false,
      setToken: (token, tokenExp, preToken, user) => {
        set((state) => ({
          ...state,
          token,
          tokenExp,
          preToken,
          user,
          isAuth: token && new Date().getTime() < tokenExp,
        }));
      },
      setPreToken: (preToken) => {
        set((state) => ({
          ...state,
          preToken,
        }));
      },
      setProfile: (profile) =>
        set((state) => ({
          ...state,
          profile,
        })),
      validateToken: () => {
        const token = get().token;
        const tokenExp = get().tokenExp;
        return token && new Date().getTime() < tokenExp;
      },
      getToken: () => {
        const token = get().token ?? get().preToken;
        return token;
      },
      setValidationUser: (email) =>
        set((state) => ({
          ...state,
          validationEmail: email,
          registervalidation: true
        })),
      logout: () =>
        set(() => ({
          token: null,
          tokenExp: null,
          isAuth: false,
          profile: null,
        })),
    }),
    {
      name: "auth",
      partialize: (state) => ({
        token: state.token,
        tokenExp: state.tokenExp,
      }),
    }
  )
);
