import PropTypes from "prop-types";
import { forwardRef } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Badge, Avatar } from "@mui/material";

// ----------------------------------------------------------------------

// const getCharAtName = (name) => name && name.charAt(0).toUpperCase();
const getCharAtName = (names) => {
  let initials = "";
  names.split(" ").map((name) => {
    initials = initials + name[0];
  });

  if (initials.length > 2) {
    initials = initials.slice(0, 2);
  }

  return {
    children: initials.toUpperCase(),
  };
}

// ----------------------------------------------------------------------

const CustomAvatar = forwardRef(
  ({ name = "", src, children, sx, ...other }, ref) => {
    const theme = useTheme();

    return (
      <Avatar
        ref={ref}
        sx={{
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary?.main,
          fontWeight: theme.typography.fontWeightRegular ,
          ...sx,
        }}
        {...other}
        {...getCharAtName(name)}
        {...(src && {src})}
      />

    );
  }
);

CustomAvatar.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.string,
};

export default CustomAvatar;
