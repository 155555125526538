// @mui
import PropTypes from "prop-types";
import { Stack, Box } from "@mui/material";
// config
import { NAV } from "../../../config-global";
// utils
import { hideScrollbarX } from "../../../utils/cssStyles";
// components
import LogoMini from "../../../components/logo/LogoMini";
import { NavSectionMini } from "../../../components/nav-section";
//
import NavToggleButton from "./NavToggleButton";
import LogoutMini from "./LogoutMini";

// ----------------------------------------------------------------------

NavMini.propTypes = {
  nav: PropTypes.array,
};

export default function NavMini({ nav }) {
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: { md: NAV.W_DASHBOARD_MINI },

        bgcolor: (theme) => theme.palette.background.navBar,
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: "fixed",
          width: NAV.W_DASHBOARD_MINI,
          ...hideScrollbarX,
        }}
      >
        <LogoMini sx={{ mx: "auto", my: 2 }} />

        <NavSectionMini
          data={nav}
          sx={{
            py: 4,
          }}
        />

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" justifyContent="center">
          <LogoutMini />
        </Stack>
      </Stack>
    </Box>
  );
}
