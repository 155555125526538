// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_LEGAL = '/legal';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: "/inicio_sesion",
  register: '/registro',
  seleccionObra:'/seleccion_obra',
  seleccionEmpresa:'/seleccion_empresa',

  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: "/recuperacion_contraseña",
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  reports: path(ROOTS_DASHBOARD, '/reportes'),
  warehouses: path(ROOTS_DASHBOARD, '/almacen'),
  activesInventory: path(ROOTS_DASHBOARD, '/activos'),
  supplier: path(ROOTS_DASHBOARD, '/proveedores'),
  employees: path(ROOTS_DASHBOARD, '/empleados'),
  obras: path(ROOTS_DASHBOARD, '/obras'),
  contractors: path(ROOTS_DASHBOARD, '/contratistas'),
  enterprise: path(ROOTS_DASHBOARD, '/miempresa'),
  enterpriseConfig: path(ROOTS_DASHBOARD, '/miempresa/configuracion'),
  profile: path(ROOTS_DASHBOARD, '/perfil'),
  devices: path(ROOTS_DASHBOARD, '/dispositivos'),
  workingTime: path(ROOTS_DASHBOARD, '/reportes/tiempo-trabajo'),
};

export const PATH_LEGAL = {
  employees: path(ROOTS_LEGAL, '/empleados'),
};
