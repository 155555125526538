import axios from "axios";

export function AxiosInterceptor({ getBaseURL, getToken, getIsAuth, logout }) {
  axios.defaults.baseURL = getBaseURL();
  // axios.defaults.httpAgent = new https.Agent({ keepAlive: true });

  const handleExit = () => {
    logout();
  };

  const updateHeader = (request, isMultiPart) => {
    const token = getToken();
    const isAuth = getIsAuth();

    if (isAuth) {
      const newHeaders = {
        Authorization: token,
        "Content-Type": isMultiPart
          ? "multipart/form-data"
          : "application/json",
      };
      request.headers = newHeaders;
    } else {
      handleExit();
    }

    return request;
  };

  axios.interceptors.request.use((request) => {
    // console.log("request", request);
    const isMultiPart =
      request.url?.includes("document") ||
      request.url?.includes("upload") ||
      (request.url?.includes("/api/v1/rules/trabajadores") &&
        (request.method == "put" || request.method == "post"));
    return updateHeader(request, isMultiPart);
  });

  axios.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      if (error.response.status === 401) {
        handleExit();
        return new Promise((resolve, reject) => {
          reject(error);
        });
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );
}
