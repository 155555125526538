import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export const usePermissionStore = create((set, get) => ({
  permission: null,
  modules: null,
  permisionsAccess: [],
  setPermission: (permission) =>
    set((state) => ({
      ...state,
      permission,
    })),
  setModules: (modules) =>
    set((state) => ({
      ...state,
      modules,
    })),
  setPermisionsAccess: (permission, modules) => {
    const _permission = permission[0].Permisions;
    const permisionsAccess = [];

    Object.keys(_permission).map(function (key, index) {
      if (_permission[key]?.habilitar) {
        const module = modules.find((x) => x.nombre == key);

        if (module?.menu) {
          permisionsAccess.push(module);
        }
      }
    });
    set((state) => ({
      ...state,
      permission: _permission,
      modules,
      permisionsAccess: permisionsAccess.sort(
        (a, b) => a.posicion - b.posicion
      ),
    }));
  },
  getPermission: () => {
    const permission = get().permission;
    const modules = get().modules;
    let result = [];

    result = modules?.map((_modules) => {
      return {
        ..._modules,
        permission: permission[_modules.nombre],
      };
    });

    return result;
  },
  getReportsModules: () => {
    const permission = get().getPermission();
    const result = permission?.filter(
      (x) => x.padre == "Reportes" && x.permission.habilitar
    );
    return result;
  },
  getWarehousesModules: () => {
    const permission = get().getPermission();
    const result = permission?.filter(
      (x) => x.padre == "Almacén" && x.permission?.habilitar && (x.nombre == "Almacenes" || x.nombre == "Proveedores" || x.nombre == "Inventario de activos" || x.nombre == "Asignación de activos")
    );
    return result;
  },
  getReportsDevices: () => {
    const permission = get().getPermission();
    const result = permission?.filter(
      (x) => x.padre == "Dispositivos" && x.permission.habilitar
    );
    return result;
  },
}));
