// routes
import { PATH_DASHBOARD } from './routes/paths';
// require("dotenv").config();

// API
// ----------------------------------------------------------------------

export const SERVER_IP = process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_API_PRODUCTION
      : process.env.REACT_APP_ENV === "testing"
      ? process.env.REACT_APP_API_TESTING
      : process.env.REACT_APP_API_DEVELOPMENT;

export const MAP_API = process.env.REACT_APP_GOOGLE_API_KEY;

// ROOT PATH AFTER LOGIN SUCCESSFUL
// export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 75,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 25,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
