import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";


async function fetchNotificationsFilters(pageParam) {
  console.log("pageParam", pageParam);
  var params = new URLSearchParams();
  params.append("limit", 8);
  params.append("page", pageParam);

  const { data } = await axios.get("/api/v1/notification/", {
    params,
  });

  return data.data;
}

async function fetchConfigNotifications(Notifications) {
  return await axios.patch(`/api/v1/security/user/profile/`, { Notifications });
}

async function fetchViewNotifications(id) {
  return await axios.patch(`/api/v1/notification/${id}`, { viewed: true });
}

async function fetchReadAllNotifications() {
  return await axios.post("/api/v1/notification/readall");
}

export function useNotificationsInfinite() {
  const result = useInfiniteQuery(
    ["notifications"],
    ({ pageParam = 0 }) => fetchNotificationsFilters(pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage.Metadata.Hasmore) return false;
        return lastPage.Metadata.Page + 1;
      },
    }
  );

  const Notifications =
    result.data?.pages.reduce(
      (prevData, page) => prevData.concat(page.Results),
      []
    ) ?? [];

  const totalUnRead = result?.data?.pages[0]?.TotalUnRead ?? 0;

  return { ...result, notifications: Notifications ?? [], totalUnRead };
}

export function useFetchConfigNotifications() {
  return useMutation(fetchConfigNotifications);
}

export function useFetchViewNotifications() {
  const queryClient = useQueryClient();

  return useMutation(fetchViewNotifications, {
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
  });
}

export function useFetchReadAllNotifications() {
  const queryClient = useQueryClient();

  return useMutation(fetchReadAllNotifications, {
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
  });
}


