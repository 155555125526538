import { create } from "zustand";

const initialState = {
  employeeFilters: null,
  projectsFilters: null,
  contractorsFilters: null,
  usersFilters: null,
  reportsEventsFilters: null,
  reportsAttendanceFilters: null,
  reportsWorkingTimeFilters: null,
};

export const useFilterStore = create((set, get) => ({
  ...initialState,
  setEmployeeFilters: (query) => {
    set((state) => ({
      ...state,
      employeeFilters: query,
    }));
  },

  setProjectsFilters: (query) => {
    set((state) => ({
      ...state,
      projectsFilters: query,
    }));
  },

  setContractorsFilters: (query) => {
    set((state) => ({
      ...state,
      contractorsFilters: query,
    }));
  },

  setUsersFilters: (query) => {
    set((state) => ({
      ...state,
      usersFilters: query,
    }));
  },

  setReportsEventsFilters: (query) => {
    set((state) => ({
      ...state,
      reportsEventsFilters: query,
    }));
  },

  setReportsAttendanceFilters: (query) => {
    set((state) => ({
      ...state,
      reportsAttendanceFilters: query,
    }));
  },

  setReportsWorkingTimeFilters: (query) => {
    set((state) => ({
      ...state,
      reportsWorkingTimeFilters: query,
    }));
  },
  resetFilters: () => {
    set(() => initialState);
  },
}));
