import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
import PreAuthGuard from "../auth/PreAuthGuard";

// // layouts
// import MainLayout from "../layouts/main";
// import SimpleLayout from "../layouts/simple";
// import CompactLayout from "../layouts/compact";
// import DashboardLayout from "../layouts/dashboard";
import DashboardLayout from "../layouts/dashboard";
import CompactLayout from "../layouts/compact";

// // config
// import { PATH_AFTER_LOGIN } from "../config-global";
// //
import {
  // Auth
  LoginPage,
  ProyectSelectorPage,
  EnterpriseSelectorPage,
  ResetPasswordPage,
  RegisterPage,
  WelcomePage,
  UserAccountPage,
  UsersPage,
  RoleManagementPage,
  ReportsPage,
  EmployeesPage,
  DetailsEmployeesPage,
  EmployeesHistoryPage,
  RegisterEmployeesPage,
  EditEmployeesPage,
  EmployeesBehavioralProfile,
  ContractorsPage,
  DetailsContractorsPage,
  ContractorsHistoryPage,
  RegisterContractorsPage,
  EditContractorsPage,
  ProjectsPage,
  RegisterProjectsPage,
  EditProjectsPage,
  DetailsProjectsPage,
  EnterprisePage,
  ReportsEventsPage,
  ReportsAssistancePage,
  ReportsWorkingTimePage,
  WorkingTimeDetailPage,
  DevicesPage,
  Cameraspage,
  GatewaysPage,
  EnterpriseSettingsPage,
  ReportSettingsPage,
  AssetsSettingsPage,
  ShiftSettingsPage,
  EnterpriseHistoryPage,
  EditEnterprisePage,
  WarehousesPage,
  AssetWarehousesPage,
  RegisterSupplierPage,
  SupplierPage,
  EditSupplierPage,
  AssetInventoryPage,
  RegisterAssetPage,
  EditAssetPage,
  RegisterAssetRentPage,
  EditAssetRentPage,
  DetailsAssetInventoryPage,
  AssignmentAssetsPage,
  AssignmentAssetsDetailPage,
  DashboardBilling,
  DashboardBillingDetail,
  Dashboard,
  EmployeesLegalPage,
  DetailsEmployeesLegalPage,
  EmployeesHistoryLegalPage,
  HierarchyPage,
  DetailsHierarchyPage,
  ApprovalsPage,
  RegisterBehavioralProfile,
  EditBehavioralProfile,
  Page404,
  Page403,
} from "./elements";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "inicio_sesion",
      // element: <GuestGuard />,
      children: [
        {
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
          index: true,
        },
        {
          path: "ventas",
          element: (
            <GuestGuard>
              <LoginPage type={"sales"} />
            </GuestGuard>
          ),
          // index: true,
        },
      ],
    },
    // {
    //   path: "inicio_sesion",
    //   element: (
    //     <GuestGuard>
    //       <LoginPage />
    //     </GuestGuard>
    //   ),
    // },
    {
      path: "registro",
      element: (
        <GuestGuard>
          <RegisterPage />
        </GuestGuard>
      ),
    },
    {
      path: "bienvenido",
      element: <CompactLayout maxWidth={700} />,
      // children: [{ element: <WelcomePage />, index: true }],
      children: [
        {
          element: (
            <PreAuthGuard>
              <WelcomePage />
            </PreAuthGuard>
          ),

          index: true,
        },
      ],
    },
    {
      path: "seleccion_obra",
      element: (
        <PreAuthGuard>
          <ProyectSelectorPage />
        </PreAuthGuard>
      ),
    },
    {
      path: "seleccion_empresa",
      element: (
        <PreAuthGuard>
          <EnterpriseSelectorPage />
        </PreAuthGuard>
      ),
    },
    {
      path: "recuperacion_contraseña",
      element: <CompactLayout />,
      children: [{ element: <ResetPasswordPage />, index: true }],
    },
    {
      path: "legal",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "empleados",
          children: [
            {
              element: (
                <AuthGuard>
                  <EmployeesLegalPage />
                </AuthGuard>
              ),
              index: true,
            },
            {
              path: ":id",
              element: (
                <AuthGuard>
                  <DetailsEmployeesLegalPage />
                </AuthGuard>
              ),
            },
            {
              path: "historial/:id",
              element: (
                <AuthGuard>
                  <EmployeesHistoryLegalPage />
                </AuthGuard>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "general",
          children: [
            {
              element: <Dashboard />,
              index: true,
            },
          ],
          // index: true,
        },
        {
          path: "facturacion",
          children: [
            {
              element: <DashboardBilling />,
              index: true,
            },
            {
              path: ":id",
              element: <DashboardBillingDetail />,
              index: true,
            },
          ],
        },
        {
          path: "perfil",
          element: (
            // <AuthGuard>
            // </AuthGuard>
            <UserAccountPage />
          ),
        },
        {
          path: "usuarios",
          children: [
            {
              element: (
                <AuthGuard>
                  <UsersPage />
                </AuthGuard>
              ),
              index: true,
            },
            {
              path: "roles",
              element: (
                <AuthGuard>
                  <RoleManagementPage />
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: "empleados",
          children: [
            {
              element: (
                <AuthGuard>
                  <EmployeesPage />
                </AuthGuard>
              ),
              index: true,
            },
            {
              path: ":id",
              element: (
                <AuthGuard>
                  <DetailsEmployeesPage />
                </AuthGuard>
              ),
            },
            {
              path: "historial/:id",
              element: (
                <AuthGuard>
                  <EmployeesHistoryPage />
                </AuthGuard>
              ),
            },
            {
              path: "conducta/:id",
              element: (
                <AuthGuard>
                  <EmployeesBehavioralProfile />
                </AuthGuard>
              ),
            },
            {
              path: "crear",
              element: (
                <AuthGuard>
                  <RegisterEmployeesPage />
                </AuthGuard>
              ),
            },
            {
              path: "editar/:id",
              element: (
                <AuthGuard>
                  <EditEmployeesPage />
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: "conducta",
          children: [
            {
              path: "crear/:id",
              element: (
                <AuthGuard>
                  <RegisterBehavioralProfile />
                </AuthGuard>
              ),
            },
            {
              path: "editar/:id",
              element: (
                <AuthGuard>
                  <EditBehavioralProfile />
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: "contratistas",
          children: [
            {
              element: (
                <AuthGuard>
                  <ContractorsPage />
                </AuthGuard>
              ),
              index: true,
            },
            {
              path: ":id",
              element: (
                <AuthGuard>
                  <DetailsContractorsPage />
                </AuthGuard>
              ),
            },
            {
              path: "crear",
              element: (
                <AuthGuard>
                  <RegisterContractorsPage />
                </AuthGuard>
              ),
            },
            {
              path: "editar/:id",
              element: (
                <AuthGuard>
                  <EditContractorsPage />
                </AuthGuard>
              ),
            },

            {
              path: "historial/:id",
              element: (
                <AuthGuard>
                  <ContractorsHistoryPage />
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: "obras",
          // element: <AuthGuard/>,
          children: [
            {
              element: (
                <AuthGuard>
                  <ProjectsPage />
                </AuthGuard>
              ),
              index: true,
            },
            { path: "crear", element: <RegisterProjectsPage /> },
            { path: "editar/:id", element: <EditProjectsPage /> },
            { path: ":id", element: <DetailsProjectsPage /> },
          ],
          // element: (
          //   <AuthGuard>
          //     <ProjectsPage />
          //   </AuthGuard>
          // ),

          // path: "recuperacion_contraseña",
          // element: <CompactLayout />,
          // children: [{ element: <ResetPasswordPage />, index: true }],
        },
        {
          path: "reportes",
          children: [
            {
              element: (
                <AuthGuard>
                  <ReportsPage />
                </AuthGuard>
              ),
              index: true,
            },
            {
              path: "asistencias",
              element: (
                <AuthGuard>
                  <ReportsEventsPage />
                </AuthGuard>
              ),
            },
            {
              path: "lista-asistencias",
              element: (
                <AuthGuard>
                  <ReportsAssistancePage />
                </AuthGuard>
              ),
            },

            {
              path: "tiempo-trabajo",
              children: [
                {
                  element: (
                    <AuthGuard>
                      <ReportsWorkingTimePage />
                    </AuthGuard>
                  ),
                  index: true,
                },
                {
                  path: ":id",
                  element: (
                    <AuthGuard>
                      <WorkingTimeDetailPage />
                    </AuthGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "dispositivos",
          children: [
            {
              element: (
                <AuthGuard>
                  <DevicesPage />
                </AuthGuard>
              ),
              index: true,
            },
            {
              path: "camaras",
              element: (
                <AuthGuard>
                  <Cameraspage />
                </AuthGuard>
              ),
            },
            {
              path: "gateway",
              element: (
                <AuthGuard>
                  <GatewaysPage />
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: "miempresa",
          children: [
            {
              element: (
                <AuthGuard>
                  <EnterprisePage />
                </AuthGuard>
              ),
              index: true,
            },
            {
              path: "configuracion",
              children: [
                {
                  element: (
                    <AuthGuard>
                      <EnterpriseSettingsPage />
                    </AuthGuard>
                  ),
                  index: true,
                },
                {
                  path: "reportes",
                  element: (
                    <AuthGuard>
                      <ReportSettingsPage />
                    </AuthGuard>
                  ),
                },
                {
                  path: "activos",
                  element: (
                    <AuthGuard>
                      <AssetsSettingsPage />
                    </AuthGuard>
                  ),
                },
                {
                  path: "turnos",
                  element: (
                    <AuthGuard>
                      <ShiftSettingsPage />
                    </AuthGuard>
                  ),
                },
              ],
            },
            {
              path: "historial/:id",
              element: (
                <AuthGuard>
                  <EnterpriseHistoryPage />
                </AuthGuard>
              ),
            },
            {
              path: "editar/:id",
              element: (
                <AuthGuard>
                  <EditEnterprisePage />
                </AuthGuard>
              ),
            },
          ],
        },

        {
          path: "almacen",
          children: [
            {
              element: (
                <AuthGuard>
                  <WarehousesPage />
                </AuthGuard>
              ),
              index: true,
            },
            {
              path: "almacenes",
              element: (
                <AuthGuard>
                  <AssetWarehousesPage />
                </AuthGuard>
              ),
            },
          ],
        },

        {
          path: "proveedores",
          children: [
            {
              element: (
                <AuthGuard>
                  <SupplierPage />
                </AuthGuard>
              ),
              index: true,
            },
            {
              path: "crear",
              element: (
                <AuthGuard>
                  <RegisterSupplierPage />
                </AuthGuard>
              ),
            },

            {
              path: "editar/:id",
              element: (
                <AuthGuard>
                  <EditSupplierPage />
                </AuthGuard>
              ),
            },
          ],
        },

        {
          path: "activos",
          children: [
            {
              element: (
                <AuthGuard>
                  <AssetInventoryPage />
                </AuthGuard>
              ),
              index: true,
            },
            {
              path: "renta/crear",
              element: (
                <AuthGuard>
                  <RegisterAssetRentPage />
                </AuthGuard>
              ),
            },

            {
              path: "renta/editar/:id",
              element: (
                <AuthGuard>
                  <EditAssetRentPage />
                </AuthGuard>
              ),
            },
            { path: ":id", element: <DetailsAssetInventoryPage /> },

            //
            {
              path: "crear",
              element: (
                <AuthGuard>
                  <RegisterAssetPage />
                </AuthGuard>
              ),
            },

            {
              path: "editar/:id",
              element: (
                <AuthGuard>
                  <EditAssetPage />
                </AuthGuard>
              ),
            },
          ],
        },

        {
          path: "asignacion",
          children: [
            {
              element: (
                <AuthGuard>
                  <AssignmentAssetsPage />
                </AuthGuard>
              ),
              index: true,
            },

            {
              path: "detalle/:id",
              element: (
                <AuthGuard>
                  <AssignmentAssetsDetailPage />
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: "jerarquia",
          children: [
            {
              element: (
                <AuthGuard>
                  <HierarchyPage />
                </AuthGuard>
              ),
              index: true,
            },
            {
              path: "detalle/:id",
              element: (
                <AuthGuard>
                  <DetailsHierarchyPage />
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: "aprobaciones",
          children: [
            {
              element: (
                <AuthGuard>
                  <ApprovalsPage />
                </AuthGuard>
              ),
              index: true,
            },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
      ],
    },
    { path: "/", element: <Navigate to="/dashboard" replace /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
