import PropTypes from "prop-types";
// @mui
import { Box, Card, CardHeader, Typography, Stack } from "@mui/material";
// utils
import Scrollbar from "../../../components/scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { alpha } from "@mui/material/styles";
import NotFoundImg from "../../../assets/images/placeholder-image.jpg";

// ----------------------------------------------------------------------

ListProyects.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function ListProyects({ list, accion }) {
  return (
    <Scrollbar sx={{ maxHeight: { xs: 400 } }}>
      <Stack spacing={2} sx={{ pr: 0 }}>
        {list?.map((obra) => (
          <ProyectItem
            key={obra._id}
            // id={obra._id}
            name={obra?.proyecto}
            address={obra?.domicilio}
            photo={obra?.foto?.url}
            accion={() => accion(obra._id)}
          />
        ))}
      </Stack>
    </Scrollbar>
  );
}

// ----------------------------------------------------------------------

ProyectItem.propTypes = {
  app: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    review: PropTypes.number,
    system: PropTypes.string,
    shortcut: PropTypes.string,
  }),
};

function ProyectItem({ name, address, photo, accion }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      // onClick={() => console.log(id)}
      onClick={accion}
      sx={{
        p: 2,
        "&:hover": {
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.4),
          border: (theme) => `solid 1px ${theme.palette.primary.main}`,
          cursor: "pointer",
          borderRadius: 0.8,
        },
      }}
    >
      <Box
        sx={{
          width: 86,
          height: 48,
          flexShrink: 0,
          display: "flex",
          borderRadius: 1.5,
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "background.neutral",
        }}
      >
        <Box
          component="img"
          src={photo ?? NotFoundImg}
          sx={{ width: 86, height: 48, borderRadius: 0.8 }}
        />
      </Box>

      <Box sx={{ flexGrow: 1, minWidth: 160, maxWidth: 300 }}>
        <Typography variant="h6">{name}</Typography>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ mt: 0.5, color: "text.secondary" }}
        >
          <Stack sx={{ color: "primary.main" }}>
            <FontAwesomeIcon icon={faLocationDot} color="text.primary" />
          </Stack>

          <Typography
            variant="subtitle1"
            noWrap
            sx={{ ml: 0.8, mr: 1, color: "#C7C7C7" }}
          >
            {address}
          </Typography>
        </Stack>
      </Box>

      <Stack alignItems="flex-end" sx={{ color: "text.secondary", pr: 3 }}>
        <FontAwesomeIcon icon={faAngleRight} size="xl" />
      </Stack>
    </Stack>
  );
}
