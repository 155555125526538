import { memo } from "react";
// @mui
import { Box } from "@mui/material";
//
import AlertAnimation from "../../assets/animations/alert";
import Lottie from "lottie-react";
// ----------------------------------------------------------------------

function AlertIllustration({ sx, ...other }) {
  return (
    <Box {...sx} {...other}>
      <Lottie
        style={{ margin: "auto" }}
        animationData={AlertAnimation}
        loop={true}
        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
      />
    </Box>
  );
}

export default memo(AlertIllustration);
