import { useAuthStore } from "../store/auth";
import { AxiosInterceptor } from "../interceptors/axios.interceptor";
import { SERVER_IP } from "../config-global";

function getToken() {
  return useAuthStore.getState().token;
}

// function getTokenExp() {
//   return useAuthStore.getState().tokenExp;
// }

function getIsAuth() {
  return useAuthStore.getState().validateToken();
}

function getBaseURL() {
  return SERVER_IP;
}

function logout() {
  useAuthStore.getState().logout();
  // useAuthStore.setState({token: null, tokenExp: null, isAuth: false, profile: null })
  // return useAuthStore.getState().logout;
}

export function AxiosClient() {
  AxiosInterceptor({
    getBaseURL,
    getToken,
    getIsAuth,
    logout,
  });
}
