import { memo } from "react";
// @mui
import { Box } from "@mui/material";
//
import VerificationAnimation from "../../assets/animations/verification_animation";
import Lottie from "lottie-react";
// ----------------------------------------------------------------------

function VerificationIllustration({ sx, ...other }) {
  return (
    <Box {...sx}{...other}>
      <Lottie
        style={{ margin: "auto" }}
        animationData={VerificationAnimation}
        loop={true}
      />
    </Box>
  );
}

export default memo(VerificationIllustration);
