import { memo } from "react";
// @mui
import { Box } from "@mui/material";
//
import DeleteAnimation from "../../assets/animations/lottie_delete";
import Lottie from "lottie-react";
// ----------------------------------------------------------------------

function DeleteIllustration({ sx, ...other }) {
  return (
    <Box {...sx}{...other}>
      <Lottie
        style={{ margin: "auto" }}
        animationData={DeleteAnimation}
        loop={true}
      />
    </Box>
  );
}

export default memo(DeleteIllustration);
