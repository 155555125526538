import { useQuery } from "@tanstack/react-query";
import axios from "axios";

async function fetchPermissions(roleId) {
  const { data } = await axios.get(`/api/v1/security/role/${roleId}`);
  return data.data;
}

async function fetchModules() {
  const { data } = await axios.get("/api/v1/security/modules");
  return data.data;
}

export function useFetchPermissions(profile) {
  return useQuery({
    queryKey: ["permissions"],
    queryFn: () => fetchPermissions(profile.Role_id),
    enabled: !!profile,
    retry: false
  });
}

export function useFetchModules() {
  return useQuery({ queryKey: ["modules"], queryFn: fetchModules, retry: false });
}
