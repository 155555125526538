import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------



// const Loadable =  (props) =>
//  {
//   const [loading, setLoading] = useState(true);
// 		const retry = useCallback(() => setLoading(true), []);

//   const LazyComponent = useMemo(
//     () =>
//       lazyWithRetry(() =>
//         promise().catch(() => {
//           setLoading(false);
//           return { default: () => <ErrorRetryView retry={retry} /> };
//         })
//       ),
//     [promise, loading]
//   );
//   return (
//     <Suspense fallback={<LoadingScreen />}>
//       <LazyComponent {...props} />
//     </Suspense>
//   );
//  };

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

  const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    try {
      return await componentImport();
    } catch (error) {
      console.error(error);
      return window.location.reload();
    }
  });

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazyWithRetry(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazyWithRetry(() => import('../pages/auth/RegisterPage')));
export const WelcomePage = lazyWithRetry(() => import('../pages/auth/WelcomePage'));
export const ProyectSelectorPage = lazyWithRetry(() => import('../pages/ProyectSelectorPage'));
export const EnterpriseSelectorPage = lazyWithRetry(() => import('../pages/EnterpriseSelectorPage'));

// export const ProyectSelectorPage = Loadable(lazyWithRetry(() => import('../pages/ProyectSelectorPage')));
export const ResetPasswordPage = Loadable(lazyWithRetry(() => import('../pages/auth/ResetPasswordPage')));
export const UserAccountPage = Loadable(lazyWithRetry(() => import('../pages/users/UserAccountPage')));
export const UsersPage = Loadable(lazyWithRetry(() => import('../pages/users/UsersPage')));
export const RoleManagementPage = Loadable(lazyWithRetry(() => import('../pages/users/RoleManagementPage')));

export const EmployeesPage = Loadable(lazyWithRetry(() => import('../pages/employees/EmployeesPage')));
export const DetailsEmployeesPage = Loadable(lazyWithRetry(() => import('../pages/employees/DetailsEmployeesPage')));
export const EmployeesHistoryPage = Loadable(lazyWithRetry(() => import('../pages/employees/EmployeesHistoryPage')));
export const RegisterEmployeesPage = Loadable(lazyWithRetry(() => import('../pages/employees/RegisterEmployeesPage')));
export const EditEmployeesPage = Loadable(lazyWithRetry(() => import('../pages/employees/EditEmployeesPage')));
export const EmployeesBehavioralProfile = Loadable(lazyWithRetry(() => import('../pages/employees/EmployeesBehavioralProfile')));

export const RegisterBehavioralProfile = Loadable(lazyWithRetry(() => import('../pages/behavioralProfile/RegisterBehavioralProfile')));
export const EditBehavioralProfile = Loadable(lazyWithRetry(() => import('../pages/behavioralProfile/EditBehavioralProfile')));

export const ProjectsPage = Loadable(lazyWithRetry(() => import('../pages/projects/ProjectsPage')));
export const RegisterProjectsPage = Loadable(lazyWithRetry(() => import('../pages/projects/RegisterProjectsPage')));
export const EditProjectsPage = Loadable(lazyWithRetry(() => import('../pages/projects/EditProjectsPage')));
export const DetailsProjectsPage = Loadable(lazyWithRetry(() => import('../pages/projects/DetailsProjectsPage')));

export const ReportsPage = Loadable(lazyWithRetry(() => import('../pages/reports/')));
export const ReportsEventsPage = Loadable(lazyWithRetry(() => import('../pages/reports/ReportsEventsPage')));
export const ReportsAssistancePage = Loadable(lazyWithRetry(() => import('../pages/reports/ReportsAssistancePage')));
export const ReportsWorkingTimePage = Loadable(lazyWithRetry(() => import('../pages/reports/ReportsWorkingTimePage')));
export const WorkingTimeDetailPage = Loadable(lazyWithRetry(() => import('../pages/reports/WorkingTimeDetailPage')));

export const DevicesPage = Loadable(lazyWithRetry(() => import('../pages/devices')))
export const Cameraspage = Loadable(lazyWithRetry(() => import('../pages/devices/Cameraspage')))
export const GatewaysPage = Loadable(lazyWithRetry(() => import('../pages/devices/GatewaysPage')))

export const ContractorsPage = Loadable(lazyWithRetry(() => import('../pages/contractors/ContractorsPage')));
export const DetailsContractorsPage = Loadable(lazyWithRetry(() => import('../pages/contractors/DetailsContractorsPage')));
export const ContractorsHistoryPage = Loadable(lazyWithRetry(() => import('../pages/contractors/ContractorsHistoryPage')));
export const RegisterContractorsPage = Loadable(lazyWithRetry(() => import('../pages/contractors/RegisterContractorsPage')));
export const EditContractorsPage = Loadable(lazyWithRetry(() => import('../pages/contractors/EditContractorsPage')));

export const EnterprisePage = Loadable(lazyWithRetry(() => import('../pages/enterprise/EnterprisePage')));
export const EnterpriseHistoryPage = Loadable(lazyWithRetry(() => import('../pages/enterprise/EnterpriseHistoryPage')));
export const EnterpriseSettingsPage = Loadable(lazyWithRetry(() => import('../pages/enterprise/EnterpriseSettingsPage')))
export const ReportSettingsPage = Loadable(lazyWithRetry(() => import('../pages/enterprise/ReportSettingsPage')))
export const AssetsSettingsPage = Loadable(lazyWithRetry(() => import('../pages/enterprise/AssetsSettingsPage')))
export const EditEnterprisePage = Loadable(lazyWithRetry(() => import('../pages/enterprise/EditEnterprisePage')))
export const ShiftSettingsPage = Loadable(lazyWithRetry(() => import('../pages/enterprise/ShiftSettingsPage')))

export const WarehousesPage = Loadable(lazyWithRetry(() => import('../pages/warehouses')));
export const AssetWarehousesPage = Loadable(lazyWithRetry(() => import('../pages/warehouses/AssetWarehousesPage')));

export const SupplierPage = Loadable(lazyWithRetry(() => import('../pages/warehouses/supplier/SupplierPage')));
export const RegisterSupplierPage = Loadable(lazyWithRetry(() => import('../pages/warehouses/supplier/RegisterSupplierPage')));
export const EditSupplierPage = Loadable(lazyWithRetry(() => import('../pages/warehouses/supplier/EditSupplierPage')));

export const AssetInventoryPage = Loadable(lazyWithRetry(() => import('../pages/warehouses/assetInventory/AssetInventoryPage')));
export const DetailsAssetInventoryPage = Loadable(lazyWithRetry(() => import('../pages/warehouses/assetInventory/DetailsAssetInventoryPage')));
export const RegisterAssetPage = Loadable(lazyWithRetry(() => import('../pages/warehouses/assetInventory/RegisterAssetPage')));
export const EditAssetPage = Loadable(lazyWithRetry(() => import('../pages/warehouses/assetInventory/EditAssetPage')));
export const RegisterAssetRentPage = Loadable(lazyWithRetry(() => import('../pages/warehouses/assetInventory/RegisterAssetRentPage')));
export const EditAssetRentPage = Loadable(lazyWithRetry(() => import('../pages/warehouses/assetInventory/EditAssetRentPage')));
export const AssignmentAssetsDetailPage = Loadable(lazyWithRetry(() => import('../pages/warehouses/assignmentAssets/AssignmentAssetsDetailPage')));
export const AssignmentAssetsPage = Loadable(lazyWithRetry(() => import('../pages/warehouses/assignmentAssets/AssignmentAssetsPage')));

export const DashboardBilling = Loadable(lazyWithRetry(() => import('../pages/dashboard/DashboardBilling')));
export const DashboardBillingDetail = Loadable(lazyWithRetry(() => import('../pages/dashboard/DashboardBillingDetail')));
export const Dashboard = Loadable(lazyWithRetry(() => import('../pages/dashboard/Dashboard')));

export const EmployeesLegalPage = Loadable(lazyWithRetry(() => import('../pages/employeesLegal/EmployeesPage')));
export const DetailsEmployeesLegalPage = Loadable(lazyWithRetry(() => import('../pages/employeesLegal/DetailsEmployeesPage')));
export const EmployeesHistoryLegalPage = Loadable(lazyWithRetry(() => import('../pages/employeesLegal/EmployeesHistoryPage')));
// export const RegisterEmployeesLegalPage = Loadable(lazyWithRetry(() => import('../pages/employeesLegal/RegisterEmployeesPage')));
// export const EditEmployeesLegalPage = Loadable(lazyWithRetry(() => import('../pages/employeesLegal/EditEmployeesPage')));
export const HierarchyPage = Loadable(lazyWithRetry(() => import('../pages/hierarchy/HierarchyPage')));
export const DetailsHierarchyPage = Loadable(lazyWithRetry(() => import('../pages/hierarchy/DetailsHierarchyPage')));

export const ApprovalsPage = Loadable(lazyWithRetry(() => import('../pages/approvals/ApprovalsPage')));

export const Page404 = Loadable(lazyWithRetry(() => import('../pages/Page404')));
export const Page403 = Loadable(lazyWithRetry(() => import('../pages/Page403')));
