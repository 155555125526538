import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthStore } from "../store/auth";

async function fetchAuth(data) {
  // console.log("data", data)
  const url =
    data.type == "sales"
      ? "/api/v1/security/user/loginventas/"
      : "/api/v1/security/user/login/";

  return await axios.post(url, {
    Email: data.email,
    Password: data.password,
  });
}

async function fetchAuthObra(obraId) {
  return await axios.post(`/api/v1/loginobra/`, {
    selectedObra: obraId,
  });
}

async function fetchAuthEnterprise(enterprise) {
  return await axios.post(`/api/v1/loginenterprise/`, {
    enterprise,
  });
}

async function fetchGetObras() {
  var params = new URLSearchParams();
  params.append("projectMongo", "proyecto");
  params.append("projectMongo", "domicilio");
  params.append("projectMongo", "foto");

  const { data } = await axios.get(`/api/v1/obras/`, { params });
  return data.data;
}

async function fetchGetEnterprise() {
  var params = new URLSearchParams();
  // params.append("projectMongo", "proyecto");
  // params.append("projectMongo", "domicilio");
  // params.append("projectMongo", "foto");

  const { data } = await axios.get(`/api/v1/legal/enterprise/`, { params });
  return data.data;
}

async function fetchProfile() {
  const { data } = await axios.get(`/api/v1/security/user/profile/`);
  return data.data;
}

async function fetchResetPassword(Email) {
  return await axios.post(`/api/v1/security/user/password/renewcode/`, {
    Email,
  });
}

async function fetchChangePasswordWithCode(data) {
  return await axios.post(
    `/api/v1/security/user/password/changepasswordwithcode/`,
    {
      Email: data.email,
      Code: data.code,
      Password: data.password,
    }
  );
}

async function fetchChangePassword(data) {
  return await axios.post(`/api/v1/security/user/password/change/`, {
    currentPassword: data.oldPassword,
    newPassword: data.newPassword,
  });
}

async function fetchUpdateUser(data) {
  return await axios.patch(`/api/v1/security/user/profile/`, {
    Name: data.name,
    Email: data.email,
    Phone: data.phone,
  });
}

async function fetchRegisterUser(data) {
  return await axios.post(`/api/v1/security/user/register/`, {
    Name: data.name,
    Email: data.email,
    Password: data.password,
  });
}

async function fetchValidateUser(data) {
  return await axios.post(`/api/v1/security/user/activateToken/`, {
    ActivationCode: data.code,
    Email: data.email,
  });
}

async function fetchResendCodeUser(Email) {
  return await axios.post(`/api/v1/security/user/resendcode/`, {
    Email,
  });
}

async function fetchGetObrasByUser() {
  var params = new URLSearchParams();
  params.append("limit", 100);

  params.append("projectMongo", "proyecto");
  params.append("projectMongo", "domicilio");
  params.append("projectMongo", "foto");

  const { data } = await axios.get(`/api/v1/rules/proyectos/filter`, {
    params,
  });
  return data.data;
}

async function fetchChangeObra(id) {
  return await axios.post(`/api/v1/security/user/loginchangeobra/`, {
    selectedObra: id,
  });
}

async function fetchJoinCompany(Code) {
  return await axios.post(`/api/v1/security/user/join/`, {
    Code,
  });
}

async function fetchLicenseValidation(license) {
  return await axios.post(`/api/v1/license/exist/`, {
    LicenseId: license,
  });
}

async function fetchRegisterCompany(data) {
  // console.log("data", data);
  return await axios.post(`/api/v1/security/enterprise/registerobra/`, data);
}

export function useFetchAuth() {
  return useMutation(fetchAuth);
}

export function useFetchAuthObra() {
  return useMutation(fetchAuthObra);
}

export function useFetchAuthEnterprise() {
  return useMutation(fetchAuthEnterprise);
}

export function useFetchProfile() {
  const result = useQuery({
    queryKey: ["profile"],
    queryFn: fetchProfile,
    retry: false,
  });
  useAuthStore.getState().setProfile(result.data);
  return result;
}

export function useFetchResetPassword() {
  return useMutation(fetchResetPassword);
}

export function useFetchChangePasswordWithCode() {
  return useMutation(fetchChangePasswordWithCode);
}

export function useFetchGetObras() {
  const result = useQuery({
    queryKey: ["obrasAuth"],
    queryFn: fetchGetObras,
    retry: false,
  });
  useAuthStore.getState().setProfile(result.data);
  return result;
}

export function useFetchGetEnterprise() {
  const result = useQuery({
    queryKey: ["EnterpriseAuth"],
    queryFn: fetchGetEnterprise,
    retry: false,
  });
  // useAuthStore.getState().setProfile(result.data);
  return result;
}




export function useFetchChangePassword() {
  return useMutation(fetchChangePassword);
}

export function useFetchUpdateUser() {
  return useMutation(fetchUpdateUser);
}

export function useFetchRegisterUser() {
  return useMutation(fetchRegisterUser);
}

export function useFetchValidateUser() {
  return useMutation(fetchValidateUser);
}

export function useFetchResendCodeUser() {
  return useMutation(fetchResendCodeUser);
}

export function useFetchGetObrasByUser() {
  const result = useQuery({
    queryKey: ["obrasUser"],
    queryFn: fetchGetObrasByUser,
    retry: false,
  });
  return result;
}

export function useFetchChangeObra() {
  return useMutation(fetchChangeObra);
}

export function useFetchJoinCompany() {
  return useMutation(fetchJoinCompany);
}

export function useFetchLicenseValidation() {
  return useMutation(fetchLicenseValidation);
}

export function useFetchRegisterCompany() {
  return useMutation(fetchRegisterCompany);
}
