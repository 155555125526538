import { memo } from "react";
// @mui
import { Box } from "@mui/material";
//
import UploadAnimation from "../../assets/animations/upload_document";
import Lottie from "lottie-react";
// ----------------------------------------------------------------------

function UploadDocument({ sx, ...other }) {
  return (
    <Box {...sx} {...other}>
      <Lottie
        style={{ margin: "auto" }}
        animationData={UploadAnimation}
        loop={true}
        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
      />
    </Box>
  );
}

export default memo(UploadDocument);
