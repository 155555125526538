import { format, getTime, formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";
import { date } from "yup";

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy";

  return date && !isNaN(date) ? format(new Date(date), fm) : "";
}

export function fDay(date) {
  return new Date(date).getDate();
}

export function fMonth3Digits(date) {
  return new Date(date).toLocaleString("es-MX", {
    month: "short",
  });
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy p";

  return date ? format(new Date(date), fm) : "";
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : "";
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: es,
      })
    : "";
}

export function getMinDiff(startDate, endDate) {
  const msInMinute = 60 * 1000;

  return Math.round(Math.abs(endDate - startDate) / msInMinute);
}

export function fLocalTime(date) {
  return date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function fLocalDateTitle(date) {
  return date.toLocaleDateString("es-MX", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function fLocalDateInput(date) {
  return date.toLocaleDateString("es-MX", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function fLocalDateTime(date) {
  if (!date) return;

  return date.toLocaleDateString("es-MX", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}

export function fLocalDate(date) {
  if (!date) return;

  return date.toLocaleDateString("es-MX", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function fLocalDay(date) {
  if (!date) return;

  return date.toLocaleDateString("es-MX", {
    month: "long",
    day: "numeric",
  });
}

export function fLocalFormat(date, format) {
  if (!date) return;

  return date.toLocaleDateString("es-MX", format);
}

export function secondsToHms(d) {
  d = Number(d);
  var hours = Math.floor(d / 3600);
  var minutes = Math.floor((d % 3600) / 60);
  var seconds = Math.floor((d % 3600) % 60);
  return {
    hours,
    minutes,
    seconds,
  };
}

export function fCalculateYears(date, withMonths = false) {
  if (!date) return "--";

  const fechaActual = new Date();
  const fechaIngresada = new Date(date);
  let antiguedad = "";

  let anios = fechaActual.getFullYear() - fechaIngresada.getFullYear();
  let meses = fechaActual.getMonth() - fechaIngresada.getMonth();

  if (meses < 0) {
    anios--;
    meses += 12;
  }

  antiguedad = `${anios > 0 ? `${anios} años` : ""} ${
    withMonths ? `${meses} meses` : ""
  }`;

  return antiguedad;
}

export function getDayName(dateStr) {
  var date = new Date(dateStr);
  return date.toLocaleDateString("es-mx", { weekday: "long" });
}

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export function getDates(startDate, stopDate) {
  var dateArray = new Array();
  var currentDate = new Date(startDate.setHours(0, 0, 0, 0));

  while (currentDate <= stopDate) {
    dateArray.push({
      date: new Date(currentDate),
      dateString: fDateTime(new Date(currentDate), "dd/MM/yyyy"),
      dayName: getDayName(currentDate),
    });
    currentDate = currentDate.addDays(1);
  }

  // console.log("dateArray", dateArray);

  return dateArray;
}

export function unixTimeToTime(unixTime) {
  // unixTime = unixTime * 1000;
  if(unixTime == null || unixTime == undefined) {
    return "00:00";
  }
  
  let options = {
    hour12: false,
    timeZone: "America/Mexico_City",
    hour: "2-digit",
    minute: "2-digit",
  };
  let fecha = new Date(unixTime).toLocaleString("en-US", options);

  if (unixTime == 0) {
    return "00:00";
  }

  return fecha;
}

export function fSecondsToHMS(seconds) {
  let hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  let minutes = Math.floor(seconds / 60);
  let remainingSeconds = Math.floor(seconds % 60);
  return hours + ":" + minutes + ":" + remainingSeconds;
}
