// i18n
import "./locales/i18n";

// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lightbox
/* eslint-disable import/no-unresolved */
// import 'yet-another-react-lightbox/styles.css';
// import 'yet-another-react-lightbox/plugins/captions.css';
// import 'yet-another-react-lightbox/plugins/thumbnails.css';

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------

import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
// @mui
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
// import { StyledChart } from './components/chart';
import SnackbarProvider from "./components/snackbar";
import ScrollToTop from "./components/scroll-to-top";
import { MotionLazyContainer } from "./components/animate";
import { ThemeSettings, SettingsProvider } from "./components/settings";

import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { AxiosClient } from "./services/axiosClient";

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { useJsApiLoader, useLoadScript } from "@react-google-maps/api";
import { Fragment } from "react";

const queryClient = new QueryClient();
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

AxiosClient();

export default function App() {
  const libraries = ["places"];

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCDD-GB4oqtzXMx1xkKyZzL4hq7k-uZ7GA",
    id: "google-map-script",
    libraries,
  });

  return (
    <Fragment>
      {isLoaded && (
        <QueryClientProvider client={queryClient}>
          {/* <ReduxProvider store={store}> */}
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
            <SettingsProvider>
              <BrowserRouter>
                <ScrollToTop />
                <MotionLazyContainer>
                  <ThemeProvider>
                    <ThemeLocalization>
                      <SnackbarProvider>
                        {/* <StyledChart /> */}
                        <Router />
                      </SnackbarProvider>
                    </ThemeLocalization>
                  </ThemeProvider>
                </MotionLazyContainer>
              </BrowserRouter>
            </SettingsProvider>

            {/* </LocalizationProvider> */}
          {/* </ReduxProvider> */}
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right"/>

        </QueryClientProvider>
      )}
    </Fragment>
  );
}
