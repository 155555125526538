import { memo } from "react";
// @mui
import { Box } from "@mui/material";
//
import CorrectAnimation from "../../assets/animations/lottie_correct";
import Lottie from "lottie-react";
// ----------------------------------------------------------------------

function CorrectIllustration({ sx, loop, ...other }) {
  return (
    <Box {...sx} {...other}>
      <Lottie
        style={{ margin: "auto" }}
        animationData={CorrectAnimation}
        loop={loop ?? true}
        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
      />
    </Box>
  );
}

export default memo(CorrectIllustration);
