import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// components
import LoadingScreen from '../components/loading-screen';
//
import { useAuthStore } from "../store/auth"

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { validateToken } = useAuthStore();
  const isAuth = validateToken();

  if (isAuth) {

    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  // if (!isInitialized) {
  //   return <LoadingScreen />;
  // }

  return <> {children} </>;
}
