import { memo } from "react";
// @mui
import { Box } from "@mui/material";
//
import SecurityAnimation from "../../assets/animations/techton_security";
import Lottie from "lottie-react";

// ----------------------------------------------------------------------

function ForbiddenIllustration({ ...other }) {
  return (
    <Box {...other}>
      <Lottie
        style={{  margin: "auto" }}
        animationData={SecurityAnimation}
        loop={true}
      />
    </Box>
  );
}

export default memo(ForbiddenIllustration);
