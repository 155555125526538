import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
// components
import LoadingScreen from "../components/loading-screen";
//
import { useAuthStore } from "../store/auth";
import { usePermissionStore } from "../store/Permission";

import { PATH_AUTH, PATH_PAGE } from "../routes/paths";
import { isObjectId } from "../utils/CheckObjectId";

// ----------------------------------------------------------------------

PreAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function PreAuthGuard({ children }) {
  const { validateToken, preToken } = useAuthStore();

  const isAuth = validateToken();
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isAuth || !preToken) {
    return <Navigate to={PATH_AUTH.login} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
