import PropTypes from "prop-types";
import { useRef } from "react";
import { SnackbarProvider as NotistackProvider } from "notistack";
// @mui
import { alpha } from "@mui/material/styles";
import { Box, IconButton, Fade } from "@mui/material";
//
import Iconify from "../iconify";
//
import StyledNotistack from "./styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
// ----------------------------------------------------------------------

SnackbarProvider.propTypes = {
  children: PropTypes.node,
};

export default function SnackbarProvider({ children }) {
  const notistackRef = useRef(null);

  const onClose = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <StyledNotistack />

      <NotistackProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        TransitionComponent={Fade}
        variant="success"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        iconVariant={{
          info: <SnackbarIcon icon={faInfoCircle} color="info" />,
          success: <SnackbarIcon icon={faCheckCircle} color="success" />,
          warning: <SnackbarIcon icon={faExclamationTriangle} color="warning" />,
          error: <SnackbarIcon icon={faTimesCircle} color="error" />,
        }}
        action={(key) => (
          <IconButton size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        )}
      >
        {children}
      </NotistackProvider>
    </>
  );
}

// ----------------------------------------------------------------------

SnackbarIcon.propTypes = {
  icon: PropTypes.object,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
  ]),
};

function SnackbarIcon({ icon, color }) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        justifyContent: "center",
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <FontAwesomeIcon icon={icon} size="1x"/>
    </Box>
  );
}
