import { memo } from "react";
// @mui
import { Box } from "@mui/material";
//
import AsignacionesAnimation from "../../assets/animations/animation_asignaciones";
import Lottie from "lottie-react";
// ----------------------------------------------------------------------

function Asignacionesllustration({ sx, loop, ...other }) {
  return (
    <Box {...sx} {...other} width={130}>
      <Lottie
        style={{ margin: "auto" }}
        animationData={AsignacionesAnimation}
        loop={loop ?? true}
        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
      />
    </Box>
  );
}

export default memo(Asignacionesllustration);
