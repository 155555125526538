import PropTypes from "prop-types";
// import { noCase } from 'change-case';
import { useState } from "react";
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
// utils
import { fToNow } from "../../../utils/formatTime";
// components
import Scrollbar from "../../../components/scrollbar";
import MenuPopover from "../../../components/menu-popover";
import { IconButtonAnimate } from "../../../components/animate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  useNotificationsInfinite,
  useFetchViewNotifications,
  useFetchReadAllNotifications
} from "../../../hooks/useNotifications";
// import { StyledRootScrollbar, StyledScrollbar } from "../../../components";

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const [openPopover, setOpenPopover] = useState(null);

  const {
    notifications,
    totalUnRead,
    isLoading: isLoadingNotifications,
    fetchNextPage,
    hasNextPage,
  } = useNotificationsInfinite();
  const { mutate: viewNotifications } = useFetchViewNotifications();
  const { mutate: readAllNotifications } = useFetchReadAllNotifications();

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = () => {
    readAllNotifications();
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? "primary" : "default"}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="primary">
          <FontAwesomeIcon size="sm" icon="fa-bell" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 360, p: 0 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notificaciones</Typography>

            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Tienes {totalUnRead} mensajes no leídos
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title="Marcar todo como leido">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <FontAwesomeIcon size="sm" icon="fa-check-double" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: "solid" }} />

        <Scrollbar
          sx={{
            height: { xs: 340, sm: "auto" },
            maxHeight: { xs: 340, sm: 700 },
          }}
          scrollableNodeProps={{ id: "scrollableDiv" }}
        >
          <InfiniteScroll
            dataLength={notifications?.length}
            hasMore={hasNextPage}
            next={() => fetchNextPage()}
            scrollableTarget="scrollableDiv"
          >
            <Box display="grid" gridTemplateColumns="repeat(1, 1fr)">
              {notifications?.map((notification) => (
                <NotificationItem
                  key={notification._id}
                  notification={notification}
                  action={() => viewNotifications(notification._id)}
                />
              ))}
            </Box>
          </InfiniteScroll>
        </Scrollbar>

        {/* 
           <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Nuevos
              </ListSubheader>
            }
          >
            {notifications.slice(0, 5).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Anteriores
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>   */}

        {/* <Divider sx={{ borderStyle: "solid" }} /> */}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            Ver todo
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
    avatar: PropTypes.node,
    type: PropTypes.string,
    title: PropTypes.string,
    isUnRead: PropTypes.bool,
    description: PropTypes.string,
    createdAt: PropTypes.instanceOf(Date),
  }),
};

function NotificationItem({ notification, action }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      onClick={action}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(!notification.viewed && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "#fff" }}>{avatar}</Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.6}
            sx={{ mt: 0.5, typography: "caption", color: "text.disabled" }}
          >
            <FontAwesomeIcon
              icon="fa-clock"
              style={{
                marginBottom: 3,
              }}
            />
            <Typography variant="caption">
              {fToNow(notification.date_datetime)}
            </Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Stack direction="column">
      <Typography variant="subtitle2">{notification.title}</Typography>
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        {notification.body}
      </Typography>
    </Stack>
  );

  if (notification.type === "order_placed") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/notification/ic_package.svg"
        />
      ),
      title,
    };
  }

  return {
    // avatar: notification.avatar ? (
    //   <img alt={notification.title} src={notification.avatar} />
    // ) : null,
    avatar: (
      <Stack
        sx={{
          color: (theme) => theme.palette.primary.main,
        }}
      >
        <FontAwesomeIcon icon="fa-user-clock" />
      </Stack>
    ),
    title,
  };
}
