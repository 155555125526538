import PropTypes from "prop-types";
import {Box, LinearProgress, Stack} from "@mui/material";

// ----------------------------------------------------------------------

ProgressLinear.propTypes = {
  value: PropTypes.number,
};

export default function ProgressLinear({ value, height }) {
  return (
    <Box width={1} display="flex" alignItems="center">
      <Box
        width={1}
        mr={1}
        sx={{
          // width: 1,
          // height: 10,
          "& .MuiLinearProgress-colorPrimary": {
            backgroundColor: "#6d6d6d",
            height: height || 4,
          },
          "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#d233ff",
            height: height || 4,
          },
        }}
      >
        <LinearProgress variant="determinate" value={value ?? 0} />
      </Box>
     </Box>
  );
}
