// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Link, Typography, Stack, Popover, Button } from "@mui/material";
// auth
import { useAuthStore } from "../../../store/auth";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useState } from "react";
import ListProyects from "./ListProyects";
import {
  useFetchGetObrasByUser,
  useFetchChangeObra,
} from "../../../hooks/useAuth";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { useFilterStore } from "../../../store/filters";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
  cursor: "pointer",
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const navigate = useNavigate();
  const { setToken, profile } = useAuthStore();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { resetFilters } = useFilterStore();

  const [anchorElProjectChange, setAnchorElProjectChange] = useState(null);
  const openProjectChange = Boolean(anchorElProjectChange);
  const id = openProjectChange ? "simple-popover" : undefined;
  const { data: Obras } = useFetchGetObrasByUser();
  const { mutate: changeObra } = useFetchChangeObra();

  const handleOpenProjectChange = (event) => {
    setAnchorElProjectChange(event);
  };

  const handleCloseProjectChange = () => {
    setAnchorElProjectChange(null);
  };

  const handleChangeObra = (obraId) => {
    try {
      changeObra(obraId, {
        onError: (error) => {
          const { status, errors } = error.response.data;
          enqueueSnackbar(errors, {
            variant: status,
          });
        },
        onSuccess: (response) => {
          let exp = new Date();
          const { token, expiresIn, user } = response.data.data;
          exp.setSeconds(exp.getSeconds() + expiresIn);
          setToken(token, exp.getTime(), false, user);
          queryClient.clear()
          handleCloseProjectChange();
          resetFilters();
          navigate("/");
          enqueueSnackbar("Cambio de obra correcto", {
            variant: "success",
          });
        },
      });
    } catch (error) {
      enqueueSnackbar(error.message || error, {
        variant: "error",
      });
    }
  };

  return (
    <Fragment>
      <StyledRoot onClick={handleOpenProjectChange}>
        <Box sx={{ flexGrow: 1, minWidth: 160 }}>
          <Typography variant="subtitle2">
            {profile?.SelectedObra?.proyecto}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ mt: 0.5, color: "text.secondary" }}
          >
            <Stack sx={{ color: "primary.main" }}>
              <FontAwesomeIcon icon="fa-location-dot" color="text.primary" />
            </Stack>

            <Typography
              variant="subtitle3"
              noWrap
              sx={{ ml: 0.8, mr: 1, color: "#C7C7C7" }}
            >
              {profile?.SelectedObra?.domicilio}
            </Typography>
          </Stack>
        </Box>
        <Stack alignItems="flex-end" sx={{ color: "text.secondary", pr: 0.5 }}>
          <FontAwesomeIcon icon="fa-angle-right" size="xl" />
        </Stack>
      </StyledRoot>

      {Obras?.Results?.length > 1 && (
        <Popover
          id={id}
          open={openProjectChange}
          anchorEl={anchorElProjectChange}
          onClose={handleCloseProjectChange}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 250, left: 270 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          // anchorOrigin={{
          //   vertical: "top",
          //   horizontal: "right",
          // }}
          // transformOrigin={{
          //   vertical: "top",
          //   horizontal: "left",
          // }}
          // anchorOrigin={{
          //   vertical: "bottom",
          //   horizontal: "left",
          // }}
          PaperProps={{
            style: {
              width: 450,
              // height: 100,
              backgroundColor: "#212429",
              borderRadius: 6,
              marginTop: 10,
              // padding: editRegister ? 16 : 8,
            },
          }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
        >
          <ListProyects list={Obras?.Results} accion={handleChangeObra} />

          {/* <FontAwesomeIcon icon="fa-angle-right" size="xl" /> */}
        </Popover>
      )}
    </Fragment>
  );
}
