import { memo } from "react";
// @mui
import { Box } from "@mui/material";
//
import AlmacenesAnimation from "../../assets/animations/animation_almacenes";
import Lottie from "lottie-react";
// ----------------------------------------------------------------------

function Almacenesllustration({ sx, loop, ...other }) {
  return (
    <Box {...sx} {...other} width={110}>
      <Lottie
        style={{ margin: "auto" }}
        animationData={AlmacenesAnimation}
        loop={loop ?? true}
        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
      />
    </Box>
  );
}

export default memo(Almacenesllustration);
