import { memo } from "react";
// @mui
import { Box } from "@mui/material";
//
import Animation from "../../animations/working_time";
import Lottie from "lottie-react";
// ----------------------------------------------------------------------

function WorkingTime({ sx, ...other }) {
  return (
    <Box {...sx}{...other}>
      <Lottie
        style={{ margin: "auto", height: 160 }}
        animationData={Animation}
        loop={true}
      />
    </Box>
  );
}

export default memo(WorkingTime);
