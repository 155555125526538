import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { Box } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import { useSettingsContext } from "../../components/settings";
import {
  useFetchPermissions,
  useFetchModules,
} from "../../hooks/usePermission";
import { useFetchProfile } from "../../hooks/useAuth";
import { usePermissionStore } from "../../store/Permission";
//
import Main from "./Main";
import Header from "./header";
import NavMini from "./nav/NavMini";
import NavVertical from "./nav/NavVertical";

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext();
  const { setPermisionsAccess, permisionsAccess } = usePermissionStore();
  const { data: profile } = useFetchProfile();
  const { data: modules, isSuccess: isSuccessModules } = useFetchModules();
  const { data: permissions, isSuccess: isSuccessPermissions } =
    useFetchPermissions(profile);

  const isDesktop = useResponsive("up", "md");
  const isMini = useResponsive("between", "md", "lg");

  const [open, setOpen] = useState(false);

  const isNavMini = (themeLayout === "mini" || isMini);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isSuccessModules && isSuccessPermissions) {
      setPermisionsAccess(permissions, modules);
    }
  }, [isSuccessModules, isSuccessPermissions]);

  const renderNavVertical = (
    <NavVertical
      openNav={open}
      onCloseNav={handleClose}
      nav={permisionsAccess}
    />
  );

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { md: "flex" },
            minHeight: { md: 1 },
          }}
        >
          {isDesktop ? <NavMini nav={permisionsAccess} /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { md: "flex" },
          minHeight: { md: 1 },
        }}
      >
        {renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
