import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
// components
import LoadingScreen from "../components/loading-screen";
//
import { useAuthStore } from "../store/auth";
import { usePermissionStore } from "../store/Permission";

import { PATH_AUTH, PATH_PAGE } from "../routes/paths";
import { isObjectId } from "../utils/CheckObjectId";
import { useQueryClient } from "@tanstack/react-query";
import { use } from "i18next";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

const isPermission = (url) => {
  const keyPermission = [
    "habilitar",
    "exportar",
    "eliminar",
    "editar",
    "crear",
  ];
  return keyPermission.includes(url);
};

export default function AuthGuard({ children }) {
  const { validateToken, preToken, user } = useAuthStore();
  const { getPermission, modules, permisionsAccess } = usePermissionStore();
  const queryClient = useQueryClient();
  const permission = getPermission();

  const isAuth = validateToken();
  const isInitialized = true;

  const { pathname } = useLocation();
  let pathArray = pathname.split("/");

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuth) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    queryClient.clear();
    return <Navigate to={PATH_AUTH.login} />;
  }

  if (preToken) {
    console.log("user", user);
    if(user.AppProfile == "Legal") {
      return <Navigate to={PATH_AUTH.seleccionEmpresa} />;

    }

    if (user.Enterprise_id == null || user.Status == "Invitación enviada") {
      return <Navigate to={"/bienvenido"} />;
    }

    return <Navigate to={PATH_AUTH.seleccionObra} />;
  }

  if (pathname && permission) {
    let path = pathArray.pop();
    let _permission = "habilitar";

    if (path == "dashboard") {
      return <Navigate to={permisionsAccess[0]?.url} />;
    }

    if (isObjectId(path)) {
      path = pathArray.pop();
    }

    if (isPermission(path)) {
      _permission = path;
      path = pathArray.pop();
    }

    const mod = permission?.find((x) => x.url.split("/").pop() == path);
    // console.log("mod", mod)
    if (mod?.permission && !mod.permission[_permission]) {
      return <Navigate to={PATH_PAGE.page403} />;
    }
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
