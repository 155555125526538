import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, Link } from "@mui/material";

// ----------------------------------------------------------------------

const LogoMini = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.logo.primary;

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        // height: 100,
        display: "inline-flex",
        ...sx,
      }}
      {...other}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 156.21 183.79"
      >
        <defs>
          <linearGradient id="BG0" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="100%" stopColor="#700DC0" />
          </linearGradient>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="100%" stopColor="#9213C8" />
          </linearGradient>
          <linearGradient id="BG2" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="100%" stopColor="#B436F9" />
          </linearGradient>
        </defs>
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />
          <polygon
           fill="url(#BG0)"
            points="56.46,0 47.53,37.44 75.73,37.44 68.25,68.97 106.24,94.27 119.91,37.44 147.32,37.44 156.21,0 "
          />
          <path
            fill="url(#BG1)"
            d="M82.31 138.18c-0.79,1.57 -1.73,3.06 -2.78,4.46 -5.19,6.67 -13.18,10.57 -21.63,10.57 -15.14,0 -27.42,-12.28 -27.42,-27.42 0,-15.14 12.28,-27.42 27.42,-27.42 6.09,0 12.01,2.03 16.81,5.76 2.39,1.93 4.43,4.27 6.03,6.89l25.42 -16.57c-10.66,-16.35 -28.87,-26.21 -48.39,-26.21 -31.9,0 -57.77,25.87 -57.77,57.77 0,31.9 25.87,57.77 57.77,57.77 17.83,0 34.67,-8.25 45.61,-22.32 2.28,-2.91 4.26,-6.05 5.91,-9.36l-26.99 -13.94z"
          />
          <path
            fill="url(#BG2)"
            d="M93.35 80.17c-7.33,-5.71 -15.95,-9.56 -25.1,-11.2l-1.1 4.54 -6 25.1c8.07,0.97 15.27,5.54 19.59,12.41l25.42 -16.57c-3.44,-5.44 -7.78,-10.28 -12.81,-14.29l-0 -0z"
          />
        </g>
      </svg>

    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
});

LogoMini.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default LogoMini;
